import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { TurnConnectivityIcon } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { ITestsResult, ITurnTest } from "twillio-tests/core/TestResults";
import { Typography, withStyles } from "@material-ui/core";
import { ErrorIcon, ThumbUpIcon } from "./NewTests/UI/Icons";

interface ITurnConnectivityTestProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  config?: TestConfiguration;
  classes?: any;
}

const initialValues = {
  tcpConnectionTime: undefined,
  udpConnectionTime: undefined,
  tlsConnectionTime: undefined,
  tcpGatheringTime: undefined,
  udpGatheringTime: undefined,
  tlsGatheringTime: undefined,
  behindProxy: undefined,
};

const styles = (theme: any): any => ({
  testContainer: {
    position: "relative",
    display: "flex",
    flex: 1,
    height: "100%",
    padding: "16px",
    "& > :not(:first-child)": {
      borderRightWidth: "0px",
      borderLeftWidth: "1px",
      borderLeft: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  resultSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  resultContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  titleColor: {
    color: theme.newPalette.text.primary,
  },
  unitColor: {
    color: theme.newPalette.text.secondary,
  },
  iconSection: {
    paddingTop: "8px",
    paddingBottom: "16px",
    color: theme.newPalette.text.primary,
  },
});

const TurnConnectivityTest = (props: ITurnConnectivityTestProps) => {
  const { mainColor, testResult, theme, colorThresholds, knowledgeUrl, estimateTime, testName, config, classes } =
    props;

  let disableProtocols = config?.TurnConnectivity?.disableProtocols;
  let widget = config?.TurnConnectivity?.widget;
  let isWssEnabled = config?.TurnConnectivity?.enableWss;

  let disableProtocols2 = config?.TurnConnectivity2?.disableProtocols;
  const turnTest: ITurnTest = testResult && testResult.turnTest ? testResult.turnTest : initialValues;
  const turnTest2: ITurnTest = testResult && testResult.turnTest2 ? testResult.turnTest2 : initialValues;
  const currentThresholds = colorThresholds ? colorThresholds.TurnConnectivity : null;

  const {
    tcpConnectionTime,
    udpConnectionTime,
    tlsConnectionTime,
    behindProxy,
    error,
    meta,
    successfulConnections,
    totalConnections,
  } = turnTest;

  let objSimpleWidget = { text: "", error: "" };
  if (widget == "simple" && udpConnectionTime && udpConnectionTime && udpConnectionTime) {
    if (udpConnectionTime != "error") {
      objSimpleWidget.text = "UDP";
    } else {
      if (tcpConnectionTime != "error") {
        objSimpleWidget.text = "TCP";
      } else {
        if (tlsConnectionTime != "error") {
          objSimpleWidget.text = "TLS";
        } else {
          objSimpleWidget.error = "Cannot connect to TURN";
        }
      }
    }
  } else {
    widget = false;
  }
  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: config?.useNewWidgetDesign ? theme.newPalette.error.main : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign ? theme.newPalette.warning.main : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign ? theme.newPalette.text.primary : theme.customColors.successColor,
  };
  const { t } = useTranslation(["common", "tests"]);

  return (
    <TestHeader
      icon={<TurnConnectivityIcon />}
      title={t("tests:turnConnectivity.title")}
      tooltip={t("tests:turnConnectivity.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
      errorsCount={meta?.errorsCount}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          {(!widget || widget === "full") && (
            <>
              {(!disableProtocols || !disableProtocols.includes("udp")) && (
                <div className={classes.resultSection}>
                  <Typography variant="body1" className={classes.titleColor} id="TCT-udp">
                    UDP
                  </Typography>
                  <div className={classes.iconSection}>
                    {typeof udpConnectionTime === "number" ? <ThumbUpIcon /> : <ErrorIcon />}
                  </div>
                  <div className={classes.resultContainer}>
                    {typeof udpConnectionTime === "number" ? (
                      <>
                        <Typography
                          variant="h3"
                          style={{
                            color:
                              currentThresholds && currentThresholds.udpConnectionTime
                                ? getColorValue(
                                    {
                                      ...colorTypes,
                                      value: udpConnectionTime,
                                      warningValue: currentThresholds.udpConnectionTime.warning,
                                      errorValue: currentThresholds.udpConnectionTime.error,
                                    },
                                    currentThresholds.udpConnectionTime.isLess
                                  )
                                : defaultProps.color,
                          }}
                        >
                          {formatNumber(Number(udpConnectionTime))}
                        </Typography>
                        <Typography variant="body2" className={classes.unitColor}>
                          ms
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" className={classes.unitColor} style={{ lineHeight: "45px" }}>
                        Not connected
                      </Typography>
                    )}
                  </div>
                </div>
              )}
              {(!disableProtocols || !disableProtocols.includes("tcp")) && (
                <div className={classes.resultSection}>
                  <Typography variant="body1" className={classes.titleColor} id="TCT-tcp">
                    TCP
                  </Typography>
                  <div className={classes.iconSection}>
                    {typeof tcpConnectionTime === "number" ? <ThumbUpIcon /> : <ErrorIcon />}
                  </div>
                  <div className={classes.resultContainer}>
                    {typeof tcpConnectionTime === "number" ? (
                      <>
                        <Typography
                          variant="h3"
                          style={{
                            color:
                              currentThresholds && currentThresholds.tcpConnectionTime
                                ? getColorValue(
                                    {
                                      ...colorTypes,
                                      value: tcpConnectionTime,
                                      warningValue: currentThresholds.tcpConnectionTime.warning,
                                      errorValue: currentThresholds.tcpConnectionTime.error,
                                    },
                                    currentThresholds.tcpConnectionTime.isLess
                                  )
                                : defaultProps.color,
                          }}
                        >
                          {formatNumber(Number(tcpConnectionTime))}
                        </Typography>
                        <Typography variant="body2" className={classes.unitColor}>
                          ms
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" className={classes.unitColor} style={{ lineHeight: "45px" }}>
                        Not connected
                      </Typography>
                    )}
                  </div>
                </div>
              )}
              {(!disableProtocols || !disableProtocols.includes("tls")) && (
                <div className={classes.resultSection}>
                  <Typography variant="body1" className={classes.titleColor} id="TCT-tls">
                    TLS
                  </Typography>
                  <div className={classes.iconSection}>
                    {typeof tlsConnectionTime === "number" ? <ThumbUpIcon /> : <ErrorIcon />}
                  </div>
                  <div className={classes.resultContainer}>
                    {typeof tlsConnectionTime === "number" ? (
                      <>
                        <Typography
                          variant="h3"
                          style={{
                            color:
                              currentThresholds && currentThresholds.tlsConnectionTime
                                ? getColorValue(
                                    {
                                      ...colorTypes,
                                      value: tlsConnectionTime,
                                      warningValue: currentThresholds.tlsConnectionTime.warning,
                                      errorValue: currentThresholds.tlsConnectionTime.error,
                                    },
                                    currentThresholds.tlsConnectionTime.isLess
                                  )
                                : defaultProps.color,
                          }}
                        >
                          {formatNumber(Number(tlsConnectionTime))}
                        </Typography>
                        <Typography variant="body2" className={classes.unitColor}>
                          ms
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" className={classes.unitColor} style={{ lineHeight: "45px" }}>
                        Not connected
                      </Typography>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {(!widget || widget === "full") && testResult?.turnTest2 && (
            <>
              {(!disableProtocols2 || !disableProtocols2.includes("udp")) && (
                <div className={classes.resultSection}>
                  <Typography variant="body1" className={classes.titleColor} id="TCT-r-udpConnectionTime-Full">
                    UDP
                  </Typography>
                  <div className={classes.iconSection}>
                    {typeof turnTest2.udpConnectionTime === "number" ? <ThumbUpIcon /> : <ErrorIcon />}
                  </div>
                  <div className={classes.resultContainer}>
                    {typeof turnTest2.udpConnectionTime === "number" ? (
                      <>
                        <Typography
                          variant="h3"
                          style={{
                            color:
                              currentThresholds && currentThresholds.udpConnectionTime
                                ? getColorValue(
                                    {
                                      ...colorTypes,
                                      value: turnTest2.udpConnectionTime,
                                      warningValue: currentThresholds.udpConnectionTime.warning,
                                      errorValue: currentThresholds.udpConnectionTime.error,
                                    },
                                    currentThresholds.udpConnectionTime.isLess
                                  )
                                : defaultProps.color,
                          }}
                        >
                          {formatNumber(Number(turnTest2.udpConnectionTime))}
                        </Typography>
                        <Typography variant="body2" className={classes.unitColor}>
                          ms
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" className={classes.unitColor} style={{ lineHeight: "45px" }}>
                        Not connected
                      </Typography>
                    )}
                  </div>
                </div>
              )}
              {(!disableProtocols2 || !disableProtocols2.includes("tcp")) && (
                <div className={classes.resultSection}>
                  <Typography variant="body1" className={classes.titleColor} id="TCT-r-tcpConnectionTime-Full">
                    TCP
                  </Typography>
                  <div className={classes.iconSection}>
                    {typeof turnTest2.tcpConnectionTime === "number" ? <ThumbUpIcon /> : <ErrorIcon />}
                  </div>
                  <div className={classes.resultContainer}>
                    {typeof turnTest2.tcpConnectionTime === "number" ? (
                      <>
                        <Typography
                          variant="h3"
                          style={{
                            color:
                              currentThresholds && currentThresholds.tcpConnectionTime
                                ? getColorValue(
                                    {
                                      ...colorTypes,
                                      value: turnTest2.tcpConnectionTime,
                                      warningValue: currentThresholds.tcpConnectionTime.warning,
                                      errorValue: currentThresholds.tcpConnectionTime.error,
                                    },
                                    currentThresholds.tcpConnectionTime.isLess
                                  )
                                : defaultProps.color,
                          }}
                        >
                          {formatNumber(Number(turnTest2.tcpConnectionTime))}
                        </Typography>
                        <Typography variant="body2" className={classes.unitColor}>
                          ms
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" className={classes.unitColor} style={{ lineHeight: "45px" }}>
                        Not connected
                      </Typography>
                    )}
                  </div>
                </div>
              )}
              {(!disableProtocols2 || !disableProtocols2.includes("tls")) && (
                <div className={classes.resultSection}>
                  <Typography variant="body1" className={classes.titleColor} id="TCT-r-tlsConnectionTime-Full">
                    TLS
                  </Typography>
                  <div className={classes.iconSection}>
                    {typeof turnTest2.tlsConnectionTime === "number" ? <ThumbUpIcon /> : <ErrorIcon />}
                  </div>
                  <div className={classes.resultContainer}>
                    {typeof turnTest2.tlsConnectionTime === "number" ? (
                      <>
                        <Typography
                          variant="h3"
                          style={{
                            color:
                              currentThresholds && currentThresholds.tlsConnectionTime
                                ? getColorValue(
                                    {
                                      ...colorTypes,
                                      value: turnTest2.tlsConnectionTime,
                                      warningValue: currentThresholds.tlsConnectionTime.warning,
                                      errorValue: currentThresholds.tlsConnectionTime.error,
                                    },
                                    currentThresholds.tlsConnectionTime.isLess
                                  )
                                : defaultProps.color,
                          }}
                        >
                          {formatNumber(Number(turnTest2.tlsConnectionTime))}
                        </Typography>
                        <Typography variant="body2" className={classes.unitColor}>
                          ms
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" className={classes.unitColor} style={{ lineHeight: "45px" }}>
                        Not connected
                      </Typography>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="tests-card-content tests-card-turn tst-connectivity">
          {(!widget || widget === "full") && (
            <div className="tests-card-turn__box">
              {(!disableProtocols || !disableProtocols.includes("udp")) && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id="TCT-udp">
                    udp
                  </span>
                  <span className="tests-card-turn__value" id="TCT-s-udpConnectionTime">
                    <RenderValues
                      tstId="tst-udp"
                      {...defaultProps}
                      value={udpConnectionTime}
                      formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                      color={
                        currentThresholds && currentThresholds.udpConnectionTime
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: udpConnectionTime,
                                warningValue: currentThresholds.udpConnectionTime.warning,
                                errorValue: currentThresholds.udpConnectionTime.error,
                              },
                              currentThresholds.udpConnectionTime.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </span>
                </div>
              )}
              {(!disableProtocols || !disableProtocols.includes("tcp")) && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id="TCT-tcp">
                    TCP
                  </span>
                  <span className="tests-card-turn__value" id="TCT-s-tcpConnectionTime">
                    <RenderValues
                      {...defaultProps}
                      tstId="tst-tcp"
                      value={tcpConnectionTime}
                      formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                      color={
                        currentThresholds && currentThresholds.tcpConnectionTime
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: tcpConnectionTime,
                                warningValue: currentThresholds.tcpConnectionTime.warning,
                                errorValue: currentThresholds.tcpConnectionTime.error,
                              },
                              currentThresholds.tcpConnectionTime.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </span>
                </div>
              )}
              {(!disableProtocols || !disableProtocols.includes("tls")) && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id="TCT-tls">
                    TLS
                  </span>
                  <span className="tests-card-turn__value" id="TCT-s-tlsConnectionTime">
                    <RenderValues
                      {...defaultProps}
                      tstId="tst-tls"
                      value={tlsConnectionTime}
                      formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                      color={
                        currentThresholds && currentThresholds.tlsConnectionTime
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: tlsConnectionTime,
                                warningValue: currentThresholds.tlsConnectionTime.warning,
                                errorValue: currentThresholds.tlsConnectionTime.error,
                              },
                              currentThresholds.tlsConnectionTime.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </span>
                </div>
              )}
              {isWssEnabled && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id="TCT-wss">
                    WSS
                  </span>
                  <span className="tests-card-turn__value" id="TCT-s-totalConnections">
                    <RenderValues
                      {...defaultProps}
                      tstId="tst-wss"
                      value={
                        successfulConnections && totalConnections
                          ? `${successfulConnections} of ${totalConnections}`
                          : "-"
                      }
                      formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                      color={
                        successfulConnections && totalConnections
                          ? successfulConnections === totalConnections
                            ? defaultProps.color
                            : "red"
                          : "#172240"
                      }
                    />
                  </span>
                </div>
              )}
            </div>
          )}

          {widget && widget === "simple" && (
            <div className="tests-card-turn__box">
              {objSimpleWidget.text !== "" && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id={`TCT-${objSimpleWidget.text.toLowerCase()}`}>
                    {objSimpleWidget.text}
                  </span>
                  <span className="tests-card-turn__value" id={`TCT-s-${objSimpleWidget.text.toLowerCase()}`}>
                    <RenderValues {...defaultProps} value={"✓"} color="green" />
                  </span>
                </div>
              )}
              {objSimpleWidget.error !== "" && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id={`TCT-${objSimpleWidget.error}`}>
                    {objSimpleWidget.error}
                  </span>
                  <span className="tests-card-turn__value" id={`TCT-s-${objSimpleWidget.error}`}>
                    <RenderValues {...defaultProps} value={"X"} color="red" />
                  </span>
                </div>
              )}
            </div>
          )}

          {(tcpConnectionTime || tlsConnectionTime || udpConnectionTime) && (
            <div className={`tests-card-turn__${behindProxy ? "with" : "without"}-proxy`}>
              <span id="TCT-s-behindProxy">{behindProxy ? "Proxy detected" : "No proxy detected"}</span>
            </div>
          )}
          {(!widget || widget === "full") && testResult?.turnTest2 && (
            <div className="tests-card-turn__box">
              {(!disableProtocols2 || !disableProtocols2.includes("udp")) && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id="TCT-r-udpConnectionTime-Full">
                    udp
                  </span>
                  <span className="tests-card-turn__value" id="TCT-s-udpConnectionTime-Full">
                    <RenderValues
                      tstId="tst-udp"
                      {...defaultProps}
                      value={turnTest2.udpConnectionTime}
                      formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                      color={
                        currentThresholds && currentThresholds.udpConnectionTime
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: turnTest2.udpConnectionTime,
                                warningValue: currentThresholds.udpConnectionTime.warning,
                                errorValue: currentThresholds.udpConnectionTime.error,
                              },
                              currentThresholds.udpConnectionTime.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </span>
                </div>
              )}
              {(!disableProtocols2 || !disableProtocols2.includes("tcp")) && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id="TCT-r-tcpConnectionTime-Full">
                    TCP
                  </span>
                  <span className="tests-card-turn__value" id="TCT-s-tcpConnectionTime-Full">
                    <RenderValues
                      {...defaultProps}
                      tstId="tst-tcp"
                      value={turnTest2.tcpConnectionTime}
                      formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                      color={
                        currentThresholds && currentThresholds.tcpConnectionTime
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: turnTest2.tcpConnectionTime,
                                warningValue: currentThresholds.tcpConnectionTime.warning,
                                errorValue: currentThresholds.tcpConnectionTime.error,
                              },
                              currentThresholds.tcpConnectionTime.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </span>
                </div>
              )}
              {(!disableProtocols2 || !disableProtocols2.includes("tls")) && (
                <div className="tests-card-turn__item">
                  <span className="tests-card-turn__title" id="TCT-r-tlsConnectionTime-Full">
                    TLS
                  </span>
                  <span className="tests-card-turn__value" id="TCT-s-tlsConnectionTime-Full">
                    <RenderValues
                      {...defaultProps}
                      tstId="tst-tls"
                      value={turnTest2.tlsConnectionTime}
                      formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                      color={
                        currentThresholds && currentThresholds.tlsConnectionTime
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: turnTest2.tlsConnectionTime,
                                warningValue: currentThresholds.tlsConnectionTime.warning,
                                errorValue: currentThresholds.tlsConnectionTime.error,
                              },
                              currentThresholds.tlsConnectionTime.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </span>
                </div>
              )}
            </div>
          )}
          {testResult?.turnTest2 &&
            (turnTest2.tcpConnectionTime || turnTest2.tlsConnectionTime || turnTest2.udpConnectionTime) && (
              <div className={`tests-card-turn__${turnTest2.behindProxy ? "with" : "without"}-proxy`}>
                <span id="TCT-s-behindProxy-Full">
                  {turnTest2.behindProxy ? "Proxy detected" : "No proxy detected"}
                </span>
              </div>
            )}
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(TurnConnectivityTest));
