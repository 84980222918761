import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";

import TestHeader from "../../TestHeader";
import { CallQualityIco } from "../../../icons";
import { ICommonCallQulaityTestProps } from ".";
import { RenderValues } from "../../helpers/utils";
import { formatNumber, getColorValue } from "../../../../helpers/utils";
import { Typography, withStyles } from "@material-ui/core";
import ResultRow from "../UI/ResultRow";

interface INiceInContactProps extends ICommonCallQulaityTestProps {
  testCall?: any;
  theme?: any;
  classes?: any;
}

interface NiceInContactLocations {
  [ipAddress: string]: string;
}
const niceInContactLookupLocations: NiceInContactLocations = {
  // North America
  "216.20.235.68": "dalacwrtc01",
  "216.20.235.69": "dalacwrtc02",
  "216.20.235.90": "dalacwrtc03",
  "216.20.235.91": "dalacwrtc04",
  "216.20.235.92": "dalacwrtc05",
  "216.20.235.93": "dalacwrtc06",
  "216.20.235.94": "dalacwrtc07",
  "216.20.235.139": "dalacwrtc08",
  "216.20.235.140": "dalacwrtc09",
  "216.20.235.144": "dalacwrtc10",
  "216.20.235.145": "dalacwrtc11",
  "216.20.235.146": "dalacwrtc12",
  "216.20.237.68": "laxacwrtc01",
  "216.20.237.69": "laxacwrtc02",
  "216.20.237.90": "laxacwrtc03",
  "216.20.237.91": "laxacwrtc04",
  "216.20.237.92": "laxacwrtc05",
  "216.20.237.93": "laxacwrtc06",
  "216.20.237.94": "laxacwrtc07",
  "216.20.237.8": "laxacwrtc08",
  "216.20.237.9": "laxacwrtc09",
  "216.20.237.10": "laxacwrtc10",
  "216.20.237.11": "laxacwrtc11",
  "216.20.237.12": "laxacwrtc12",
  "154.60.103.169": "fraacwrtc01",
  // EMEA
  "154.48.222.169": "munacwrtc01",
  "213.198.48.144": "lonacwrtc01",
  // London
  "213.198.49.144": "manacwrtc01",
  "38.80.80.144": "ymqacwrtc01",
  // Canada
  "38.64.141.144": "ytoacwrtc01",
  "210.57.57.133": "tkyacwrtc01",
  // APAC
  "210.57.49.144": "osaacwrtc01",
  "61.14.30.153": "sgpacwrtc01",
  "103.13.81.153": "sydacwrtc01",
  "103.13.81.139": "melacwrtc01",
  // South America
  "209.14.64.144": "rioacwrtc01",
  "209.14.64.145": "rioacwrtc02",
  "200.15.3.171": "spracwrtc01",
  "200.15.3.136": "spracwrtc02",
  "83.111.4.144": "dxbacwrtc01",
  // UAE
  "83.111.3.144": "auhacwrtc01",

  // North America
  "216.20.251.155": "hydacwrtc01",
  "216.20.250.155": "bomacwrtc01",
};

const twoColsTdStyle = (error: boolean, color: string) => (error ? { color } : {});

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: "12px 16px 16px 16px",
    "& > :not(:first-child)": {
      borderRightWidth: "0px",
      borderLeftWidth: "1px",
      borderLeft: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  leftContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingRight: "7px",
    gap: "12px",
  },
  rightContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingLeft: "7px",
    gap: "12px",
  },
  niceTitle: {
    color: theme.newPalette.text.primary,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  resultSection: {
    display: "flex",
    flexDirection: "column",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  mosValue: {
    display: "flex",
    flexDirection: "column",
  },
});

const NiceInContact = (props: INiceInContactProps) => {
  const { theme, testCall, config, mainColor, currentThresholds, classes } = props;
  const {
    avgJit,
    avgPl,
    avgOutPl,
    avgRtt,
    mark,
    avgJit2,
    avgPl2,
    avgOutPl2,
    avgRtt2,
    mark2,
    remoteAddress,
    remoteAddress2,
    error,
    error1,
    error2,
    meta,
  } = testCall;

  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };

  const overall =
    mark > 4 ? "Excellent" : mark > 3 ? "Good" : mark > 2 ? "Fair" : mark > 1 ? "Poor" : "Bad";
  const overall2 =
    mark2 > 4 ? "Excellent" : mark2 > 3 ? "Good" : mark2 > 2 ? "Fair" : mark2 > 1 ? "Poor" : "Bad";

  const mosValue =
    mark && mark != "N/A"
      ? `${mark.toFixed(1)} <span className="result-extra">(${overall})</span>`
      : "No Data";

  const mosValue2 = mark2
    ? `${mark2.toFixed(1)} <span className="result-extra">(${overall2})</span>`
    : "No Data";

  const twoColsMode =
    (config?.options?.dc && config?.options?.dc?.indexOf(",") !== -1) ||
    ((remoteAddress || error1) && (remoteAddress2 || error2));

  const { t } = useTranslation(["common", "tests"]);

  return (
    <TestHeader
      icon={<CallQualityIco />}
      title={t("tests:call-quality.title")}
      tooltip={t("tests:call-quality.tooltip")}
      error={error}
      errorsCount={meta?.errorsCount}
      knowledgeUrl={props.knowledgeUrl}
      estimateTime={props.estimateTime}
      testName={props.testName}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.leftContainer}>
            <Typography className={classes.niceTitle} id="CQC-c-remote">
              {niceInContactLookupLocations[
                typeof remoteAddress === "string" ? remoteAddress.split(":")[0] : remoteAddress
              ] ||
                remoteAddress ||
                ""}
            </Typography>
            <div className={classes.resultSection}>
              <ResultRow
                variant="nice"
                label={t("Mos")}
                value={mark}
                color={
                  currentThresholds && currentThresholds.mark
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: mark,
                          warningValue: currentThresholds.mark.warning,
                          errorValue: currentThresholds.mark.error,
                        },
                        currentThresholds.mark.isLess
                      )
                    : defaultProps.color
                }
                overall={overall}
                parentId="CQC-r-MOSScore"
                childId="CQC-c-mosValue"
              />
              <ResultRow
                variant="nice"
                label={t("Round Trip")}
                value={avgRtt}
                unit="ms"
                color={
                  currentThresholds && currentThresholds.avgRtt
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgRtt,
                          warningValue: currentThresholds.avgRtt.warning,
                          errorValue: currentThresholds.avgRtt.error,
                        },
                        currentThresholds.avgRtt.isLess
                      )
                    : defaultProps.color
                }
                parentId="CQC-r-roundTrip"
                childId="CQC-c-avgRtt"
              />
              <ResultRow
                variant="nice"
                type="in"
                label={t("Packet Loss")}
                value={avgPl}
                unit="%"
                color={
                  currentThresholds && currentThresholds.avgPl
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgPl,
                          warningValue: currentThresholds.avgPl.warning,
                          errorValue: currentThresholds.avgPl.error,
                        },
                        currentThresholds.avgPl.isLess
                      )
                    : defaultProps.color
                }
                parentId="CQC-r-packetLoss"
                childId="CQC-c-avgPl"
              />
              <ResultRow
                variant="nice"
                type="out"
                label={t("Packet Loss")}
                value={avgOutPl}
                unit="%"
                color={
                  currentThresholds && currentThresholds.avgPl
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgOutPl,
                          warningValue: currentThresholds.avgPl.warning,
                          errorValue: currentThresholds.avgPl.error,
                        },
                        currentThresholds.avgPl.isLess
                      )
                    : defaultProps.color
                }
                parentId="CQC-r-outb-packetLoss"
                childId="CQC-c-outb-avgOutPl"
              />
              <ResultRow
                variant="nice"
                label={t("jitter")}
                value={avgJit}
                unit="%"
                color={
                  currentThresholds && currentThresholds.avgJit
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: avgJit,
                          warningValue: currentThresholds.avgJit.warning,
                          errorValue: currentThresholds.avgJit.error,
                        },
                        currentThresholds.avgJit.isLess
                      )
                    : defaultProps.color
                }
                parentId="CQC-r-jitter"
                childId="CQC-c-avgJit"
              />
            </div>
          </div>
          {twoColsMode && (
            <div className={classes.rightContainer}>
              <Typography className={classes.niceTitle} id="CQC-c-remote2">
                {niceInContactLookupLocations[
                  typeof remoteAddress2 === "string" ? remoteAddress2.split(":")[0] : remoteAddress2
                ] ||
                  remoteAddress2 ||
                  ""}
              </Typography>
              <div className={classes.resultSection}>
                <ResultRow
                  variant="nice"
                  label={t("Mos")}
                  value={mark2}
                  color={
                    currentThresholds && currentThresholds.mark
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: mark2,
                            warningValue: currentThresholds.mark.warning,
                            errorValue: currentThresholds.mark.error,
                          },
                          currentThresholds.mark.isLess
                        )
                      : defaultProps.color
                  }
                  size="small"
                  overall={overall2}
                  parentId="CQC-r-MOSScore2"
                  childId="CQC-c-mosValue2"
                />

                <ResultRow
                  variant="nice"
                  label={t("Round Trip")}
                  value={avgRtt2}
                  unit="ms"
                  color={
                    currentThresholds && currentThresholds.avgRtt
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgRtt2,
                            warningValue: currentThresholds.avgRtt.warning,
                            errorValue: currentThresholds.avgRtt.error,
                          },
                          currentThresholds.avgRtt.isLess
                        )
                      : defaultProps.color
                  }
                  parentId="CQC-r-roundTrip2"
                  childId="CQC-c-avgRtt2"
                />
                <ResultRow
                  variant="nice"
                  type="in"
                  label={t("Packet Loss")}
                  value={avgPl}
                  unit="%"
                  color={
                    currentThresholds && currentThresholds.avgPl
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgPl,
                            warningValue: currentThresholds.avgPl.warning,
                            errorValue: currentThresholds.avgPl.error,
                          },
                          currentThresholds.avgPl.isLess
                        )
                      : defaultProps.color
                  }
                  parentId="CQC-r-packetLoss"
                  childId="CQC-c-avgPl"
                />
                <ResultRow
                  variant="nice"
                  type="out"
                  label={t("Packet Loss")}
                  value={avgOutPl2}
                  unit="%"
                  color={
                    currentThresholds && currentThresholds.avgPl
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgOutPl2,
                            warningValue: currentThresholds.avgPl.warning,
                            errorValue: currentThresholds.avgPl.error,
                          },
                          currentThresholds.avgPl.isLess
                        )
                      : defaultProps.color
                  }
                  parentId="CQC-r-outb-packetLoss2"
                  childId="CQC-c-outb-avgOutPl2"
                />
                <ResultRow
                  variant="nice"
                  label={t("jitter")}
                  value={avgJit2}
                  unit="%"
                  color={
                    currentThresholds && currentThresholds.avgJit
                      ? getColorValue(
                          {
                            ...colorTypes,
                            value: avgJit2,
                            warningValue: currentThresholds.avgJit.warning,
                            errorValue: currentThresholds.avgJit.error,
                          },
                          currentThresholds.avgJit.isLess
                        )
                      : defaultProps.color
                  }
                  parentId="CQC-r-jitter2"
                  childId="CQC-c-avgJit2"
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={"tests-card-content tst-call-quality"}>
          <table>
            <tbody>
              {twoColsMode && (
                <tr className="two-cols-mode-header" id="CQC-r-error">
                  <td></td>
                  <td id="CQC-c-error" style={twoColsTdStyle(error1, colorTypes.errorColor)}>
                    {niceInContactLookupLocations[
                      typeof remoteAddress === "string"
                        ? remoteAddress.split(":")[0]
                        : remoteAddress
                    ] ||
                      remoteAddress ||
                      ""}
                  </td>
                  <td style={twoColsTdStyle(error2, colorTypes.errorColor)}>
                    {niceInContactLookupLocations[
                      typeof remoteAddress2 === "string"
                        ? remoteAddress2.split(":")[0]
                        : remoteAddress2
                    ] ||
                      remoteAddress2 ||
                      ""}
                  </td>
                </tr>
              )}
              <tr className="tst-mos" id="CQC-r-MOSScore">
                <td>{t("MOS Score")}</td>
                <td id="CQC-c-mosValue">
                  <RenderValues
                    {...defaultProps}
                    value={mosValue}
                    color={
                      currentThresholds && currentThresholds.mark
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: mark,
                              warningValue: currentThresholds.mark.warning,
                              errorValue: currentThresholds.mark.error,
                            },
                            currentThresholds.mark.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                {twoColsMode && (
                  <td id="CQC-c-mosValue2">
                    <RenderValues
                      {...defaultProps}
                      value={mosValue2}
                      color={
                        currentThresholds && currentThresholds.mark
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: mark2,
                                warningValue: currentThresholds.mark.warning,
                                errorValue: currentThresholds.mark.error,
                              },
                              currentThresholds.mark.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                )}
              </tr>
              <tr className="tst-round-trip" id="CQC-r-roundTrip">
                <td>{t("Round Trip")}</td>
                <td id="CQC-c-avgRtt">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value} <span>ms</span>`}
                    value={avgRtt}
                    color={
                      currentThresholds && currentThresholds.avgRtt
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgRtt,
                              warningValue: currentThresholds.avgRtt.warning,
                              errorValue: currentThresholds.avgRtt.error,
                            },
                            currentThresholds.avgRtt.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                {twoColsMode && (
                  <td id="CQC-c-avgRtt2">
                    <RenderValues
                      {...defaultProps}
                      formatting={(value: number) => `${value} <span>ms</span>`}
                      value={avgRtt2}
                      color={
                        currentThresholds && currentThresholds.avgRtt
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: avgRtt2,
                                warningValue: currentThresholds.avgRtt.warning,
                                errorValue: currentThresholds.avgRtt.error,
                              },
                              currentThresholds.avgRtt.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                )}
              </tr>
              <tr className="tst-packet-loss" id="CQC-r-packetLoss">
                <td>
                  {t("inbound")} {t("Packet Loss")}
                </td>
                <td id="CQC-c-avgPl">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value.toFixed(1)}%`}
                    value={avgPl}
                    color={
                      currentThresholds && currentThresholds.avgPl
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgPl,
                              warningValue: currentThresholds.avgPl.warning,
                              errorValue: currentThresholds.avgPl.error,
                            },
                            currentThresholds.avgPl.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                {twoColsMode && (
                  <td id="CQC-c-avgPl2">
                    <RenderValues
                      {...defaultProps}
                      formatting={(value: number) => `${value.toFixed(1)}%`}
                      value={avgPl2}
                      color={
                        currentThresholds && currentThresholds.avgPl
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: avgPl2,
                                warningValue: currentThresholds.avgPl.warning,
                                errorValue: currentThresholds.avgPl.error,
                              },
                              currentThresholds.avgPl.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                )}
              </tr>
              <tr className="tst-packet-loss" id="CQC-r-outb-packetLoss">
                <td>
                  {t("outbound")} {t("Packet Loss")}
                </td>
                <td id="CQC-c-outb-avgOutPl">
                  <RenderValues
                    {...defaultProps}
                    formatting={(value: number) => `${value.toFixed(1)}%`}
                    value={avgOutPl}
                    color={
                      currentThresholds && currentThresholds.avgPl
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: avgOutPl,
                              warningValue: currentThresholds.avgPl.warning,
                              errorValue: currentThresholds.avgPl.error,
                            },
                            currentThresholds.avgPl.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
                {twoColsMode && (
                  <td id="CQC-c-outb-avgOutPl2">
                    <RenderValues
                      {...defaultProps}
                      formatting={(value: number) => `${value.toFixed(1)}%`}
                      value={avgOutPl2}
                      color={
                        currentThresholds && currentThresholds.avgPl
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: avgOutPl2,
                                warningValue: currentThresholds.avgPl.warning,
                                errorValue: currentThresholds.avgPl.error,
                              },
                              currentThresholds.avgPl.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                )}
              </tr>
              <tr className="tst-jitter" id="CQC-r-jitter">
                <td>{t("jitter")}</td>
                <td id="CQC-c-avgJit">
                  <div className="value-row-pair">
                    <RenderValues
                      value={avgJit}
                      {...defaultProps}
                      color={
                        currentThresholds && currentThresholds.avgJit
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: avgJit,
                                warningValue: currentThresholds.avgJit.warning,
                                errorValue: currentThresholds.avgJit.error,
                              },
                              currentThresholds.avgJit.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </div>
                </td>
                {twoColsMode && (
                  <td id="CQC-c-avgJit2">
                    <div className="value-row-pair">
                      <RenderValues
                        value={avgJit2}
                        {...defaultProps}
                        color={
                          currentThresholds && currentThresholds.avgJit
                            ? getColorValue(
                                {
                                  ...colorTypes,
                                  value: avgJit2,
                                  warningValue: currentThresholds.avgJit.warning,
                                  errorValue: currentThresholds.avgJit.error,
                                },
                                currentThresholds.avgJit.isLess
                              )
                            : defaultProps.color
                        }
                      />
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(NiceInContact));
