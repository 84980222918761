import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { LogMessage } from "twillio-tests/core/TestRunInfo";
import { isString } from "lodash";
import { sha256 } from "crypto-hash";
import axios from "axios";
import i18n from "i18next";

export function formatNumber(value: number) {
  if (isNaN(value) || value === undefined) {
    return "-";
  }
  if (Intl) {
    let lang = i18n.language;
    if (lang.indexOf("pt") !== -1) {
      lang = "pt";
    }
    return new Intl.NumberFormat(lang, {
      maximumFractionDigits: value > 1 ? 2 : undefined,
    }).format(Math.round(value));
  }
  const result = parseInt(value.toString(), 10);
  return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

interface IValueProps {
  warningValue: number;
  warningValueMax?: number | null | undefined;
  warningValueMin?: number | null | undefined;
  errorValue: number;
  errorColor: string;
  warningColor: string;
  successColor: string;
  value: number | string | null | undefined;
}

export function getColorValue(
  {
    warningValue,
    errorValue,
    errorColor,
    warningColor,
    successColor,
    value,
    warningValueMin,
    warningValueMax,
  }: IValueProps,
  isLess: boolean = false
) {
  if (isNaN(Number(value))) {
    return errorColor;
  }
  if (value === undefined) {
    return null;
  }
  if (value === null || value < 0 || typeof value === "string") {
    return warningColor;
  }

  const result = +value;
  if (!isLess) {
    if (result >= errorValue) {
      return errorColor;
    }
    if (warningValueMax && warningValueMin) {
      if (warningValueMin <= result && result < warningValueMax) {
        return warningColor;
      }
    } else {
      if (result >= warningValue) {
        return warningColor;
      }
    }

    return successColor;
  }
  if (result < errorValue) {
    return errorColor;
  }
  if (warningValueMax && warningValueMin) {
    if (warningValueMin <= result && result < warningValueMax) {
      return warningColor;
    }
  } else {
    if (result <= warningValue) {
      return warningColor;
    }
  }

  return successColor;
}

export function getQueryValue(queryKey: string): string | null {
  const searchQuery = window.location.search.split("?")[1];
  let result = null;
  if (searchQuery) {
    const searchArray = searchQuery.split("&");
    searchArray.forEach((s: string) => {
      const [key, val] = s.split("=");
      if (key === queryKey) {
        return (result = val);
      }
    });
  }
  return result;
}

export function isObject(item: any) {
  if (typeof item === "boolean") {
    return false;
  }
  return item && typeof item === "object" && !Array.isArray(item);
}

export function translateText(
  textDictionary: Record<string, string>,
  key: string,
  defaultValue: string,
  isExist = false
): string {
  if (isExist) {
    return defaultValue;
  } else {
    let value = null;
    if (textDictionary) {
      value = textDictionary[key];
    }
    if (!value) {
      value = defaultValue;
    }
    return value;
  }
}

export function customTranslateText(
  customerSpecificValue: string,
  defaultValue: string,
  isExist = false
): string {
  if (isExist) {
    return customerSpecificValue;
  } else {
    return defaultValue;
  }
}

export function formatId(str: string) {
  if (!str) {
    return "unknown";
  }
  return "tst-" + str.toLowerCase().split(".").join("").split(" ").join("-");
}

export async function checkPasswordOld(userAuthKey: number[], value: string): Promise<boolean> {
  function arrayBuffersAreEqual(a: ArrayBuffer, b: ArrayBuffer) {
    return dataViewsAreEqual(new DataView(a), new DataView(b));
  }

  function dataViewsAreEqual(a: DataView, b: DataView) {
    if (a.byteLength !== b.byteLength) {
      return false;
    }
    for (let i = 0; i < a.byteLength; i++) {
      if (a.getUint8(i) !== b.getUint8(i)) {
        return false;
      }
    }
    return true;
  }

  const key1: ArrayBuffer = new Uint32Array(userAuthKey).buffer;
  const enc = new TextEncoder();
  const key2: ArrayBuffer = await crypto.subtle.digest("SHA-256", enc.encode(value));

  return arrayBuffersAreEqual(key1, key2);
}

export async function checkPassword(value: string, config: TestConfiguration): Promise<boolean> {
  const encodedPassword = await sha256(value);

  let serverResults = null;
  try {
    const netlifyBaseURL = IsLocalhost() ? "http://localhost:9000" : "";
    const result = await axios.get(
      `${netlifyBaseURL}/.netlify/functions/check-auth?customer=${
        config.customerName || ""
      }&encodedPassword=${encodedPassword || null}`
    );
    serverResults = result.data;
    return serverResults === "OK";
  } catch (err) {
    console.log("Failed to check test password.");
    console.debug({ err });
    return false;
  }
}

export const on = (obj: any, ...args: any[]) => {
  return obj.addEventListener(...args);
};

export const off = (obj: any, ...args: any[]) => obj.removeEventListener(...args);

export function IsLocalhost() {
  return (
    window.location.hostname === "localhost" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
}

export const getQueryParams = (param: string, url: string) => {
  const href = url;
  // this expression is to get the query strings
  const reg = new RegExp("[?&]" + param + "=([^&#]*)", "i");
  const queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

export const collectLocalStorage = (logMessage: LogMessage) => {
  const items = { ...localStorage };
  logMessage(`LocalStorage: ${JSON.stringify(items, null, 2)}`);
};

export const isValidRegExp = (exp: any) => {
  var isValid = true;
  try {
    new RegExp(exp);
  } catch (e) {
    isValid = false;
  }
  return isValid;
};

export function getParameters(): any {
  const result = {};
  const items = window.location.search.substr(1).split("&");

  items.forEach((item: string) => {
    const tmp = item.split("=");
    if (tmp[0] && tmp[0] !== "") {
      result[tmp[0]] = decodeURIComponent(tmp[1].replace(/\+/g, " "));
    }
  });
  return result;
}

export const getQueryParameters = () => {
  const queryParams = getParameters();
  if (queryParams.reason) {
    queryParams.title = queryParams.reason;
  }
  if (queryParams.user) {
    queryParams.email = queryParams.user;
  }

  return queryParams;
};

const escape = (str: string) => {
  const tagsToReplace = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
  };
  return str.replace(/[&<>]/g, function (tag) {
    return tagsToReplace[tag] || tag;
  });
};

export const sanitizeData = (data: any) => {
  for (const key in data) {
    if (isString(data[key])) {
      data[key] = escape(data[key]);
    } else if (isObject(data[key])) {
      sanitizeData(data[key]);
    } else {
      return data;
    }
  }
  return data;
};

export const file2Base64 = (file: File): Promise<string | boolean> => {
  return new Promise<string>((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || false);
    reader.onerror = (error: any) => reject(error);
  })
}
